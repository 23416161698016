import React from "react";
import { getImageFile, setImageFile } from "../../Services/StorageService";
const HomeSlideRight = (props) => {
	let { image, imgKey } = props;
	let src;
	if (imgKey) {
		let localImgFile = getImageFile(imgKey);
		if (localImgFile) {
			// src = localImgFile;
			src = "url('" + localImgFile + "')";
		} else {
			src = "url('" + image + "')";
			fetch(image)
				.then(function (response) {
					return response.blob();
				})
				.then(function (myBlob) {
					const reader = new FileReader();
					reader.onload = (event) => {
						setImageFile(imgKey, event.target.result);
					};
					reader.readAsDataURL(myBlob);
				});
		}
	} else {
		src = "url('" + image + "')";
		// src = image;
	}

	return (
		<img
			loading="lazy"
			className="slider-bg"
			style={{
				flex: 3,
				background: src,
				backgroundSize: "cover",
			}}
		/>
	);
};

export default HomeSlideRight;
