export default {
	envName: "rackspaceProd",
	baseURL: "https://api.frontm.ai",
	// filesAPI: "https://vrlj80akxj.execute-api.us-east-1.amazonaws.com/rackspaceProd",
	filesAPI: "https://frontm.ai/resource",
	// contentURL: "https://s3.amazonaws.com/592975064982-frontm-contentdelivery-production/botLogos/",
	contentURL: "https://frontm.ai/botLogos/",
	gmapsApiKey: "AIzaSyA21aJlgn2xSL1bmpSpGqBWWuInLp99m1k",
	paymentGatewayKey: "pk_live_Oyu2NEXa9MmN6Kq0POcBHz9J",
	soundURL: "https://frontm.ai/sounds/",
	mapboxApiAccessToken:
		"pk.eyJ1IjoiZ2FjaWx1IiwiYSI6ImNqcHh0azRhdTFjbXQzeW8wcW5vdXhlMzkifQ.qPfpVkrWbk-GSBY3uc6z3A",
	gRPCURL: "https://prod.frontm.ai:8080",
	webertcSignalServer: "frontm.ai",
	mapURL: "https://maps.frontm.ai/styles/klokantech-basic/style.json",
	gnsDomain: "voyagervoice",
	aageHempelDomain: "aagehempelEndUser",
};
