/* eslint-disable no-undef */
/*const Config = (env = "dev") => {
	switch (env) {
		case "dev":
			return {
				envName: "dev",
				baseURL: "https://elbdev.frontm.ai", // filesAPI: "https://3nf11ibj25.execute-api.us-east-1.amazonaws.com/dev",
				filesAPI: "https://3nf11ibj25.execute-api.us-east-1.amazonaws.com/dev", // contentURL:
				//   "https://s3.amazonaws.com/frontm-contentdelivery-mobilehub-1030065648/botLogos/",
				contentURL: "https://devproxy.frontm.ai/botLogos/",
				soundURL: "https://devproxy.frontm.ai/sounds/",
				gmapsApiKey: "AIzaSyA21aJlgn2xSL1bmpSpGqBWWuInLp99m1k",
				paymentGatewayKey: "pk_test_tXlIyqK3p7LCtcF9JGwDgW0C",
				mapboxApiAccessToken:
					"pk.eyJ1IjoiZ2FjaWx1IiwiYSI6ImNqcHh0azRhdTFjbXQzeW8wcW5vdXhlMzkifQ.qPfpVkrWbk-GSBY3uc6z3A",
				gRPCURL: "https://grpcdev.frontm.ai:8080",
				// webertcSignalServer: "webrtcdev.frontm.com"
				webertcSignalServer: "devproxy.frontm.ai",
				mapURL:
					"https://devproxymaps.frontm.ai/styles/klokantech-basic/style.json",
				gnsDomain: "gnsTestBots",
				aageHempelDomain: "aagehempelEndUser",
				// webertcSignalServer: "127.0.0.1"
			}; // contentURL: 'https://s3.amazonaws.com/frontm-contentdelivery/botLogos/',

		case "stage":
			return {
				envName: "stage",
				baseURL: "https://stage.frontm.ai",
				filesAPI:
					"https://6uj792e685.execute-api.us-east-1.amazonaws.com/stage",
				contentURL: "https://s3.amazonaws.com/frontm-contentdelivery/botLogos/",
				gmapsApiKey: "AIzaSyA21aJlgn2xSL1bmpSpGqBWWuInLp99m1k",
				paymentGatewayKey: "pk_test_tXlIyqK3p7LCtcF9JGwDgW0C",
				mapboxApiAccessToken:
					"pk.eyJ1IjoiZ2FjaWx1IiwiYSI6ImNqcHh0azRhdTFjbXQzeW8wcW5vdXhlMzkifQ.qPfpVkrWbk-GSBY3uc6z3A",
				gRPCURL: "https://stage.frontm.ai:8080",
				mapURL:
					"https://devproxymaps.frontm.ai/styles/klokantech-basic/style.json",
			};

		case "prod":
			//DONOT USE THIS FOR PRODUCTION CONFIGURATION
			return {
				// envName: "prod",
				// baseURL: "https://api.frontm.ai",
				// filesAPI: "https://eg4dh0bm07.execute-api.us-east-1.amazonaws.com/prod",
				// contentURL:
				//   "https://s3.amazonaws.com/prod-frontm-contentdelivery/botLogos/",
				// soundURL: "https://frontm.ai/sounds/",
				// gmapsApiKey: "AIzaSyA21aJlgn2xSL1bmpSpGqBWWuInLp99m1k",
				// paymentGatewayKey: "pk_live_Oyu2NEXa9MmN6Kq0POcBHz9J",
				// mapboxApiAccessToken:
				//   "pk.eyJ1IjoiZ2FjaWx1IiwiYSI6ImNqcHh0azRhdTFjbXQzeW8wcW5vdXhlMzkifQ.qPfpVkrWbk-GSBY3uc6z3A",
				// gRPCURL: "https://prod.frontm.ai:8080",
				// webertcSignalServer: "devproxy.frontm.ai"
			};

		case "rackspaceProd":
			return {
				envName: "rackspaceProd",
				baseURL: "https://api.frontm.ai",
				// filesAPI: "https://vrlj80akxj.execute-api.us-east-1.amazonaws.com/rackspaceProd",
				filesAPI: "https://frontm.ai/resource",
				// contentURL: "https://s3.amazonaws.com/592975064982-frontm-contentdelivery-production/botLogos/",
				contentURL: "https://frontm.ai/botLogos/",
				gmapsApiKey: "AIzaSyA21aJlgn2xSL1bmpSpGqBWWuInLp99m1k",
				paymentGatewayKey: "pk_live_Oyu2NEXa9MmN6Kq0POcBHz9J",
				soundURL: "https://frontm.ai/sounds/",
				mapboxApiAccessToken:
					"pk.eyJ1IjoiZ2FjaWx1IiwiYSI6ImNqcHh0azRhdTFjbXQzeW8wcW5vdXhlMzkifQ.qPfpVkrWbk-GSBY3uc6z3A",
				gRPCURL: "https://prod.frontm.ai:8080",
				webertcSignalServer: "frontm.ai",
				mapURL: "https://maps.frontm.ai/styles/klokantech-basic/style.json",
				gnsDomain: "voyagervoice",
				aageHempelDomain: "aagehempelEndUser",
			};

		case "local":
			return {
				envName: "local",
				baseURL: "http://localhost:4000",
				filesAPI: "https://3nf11ibj25.execute-api.us-east-1.amazonaws.com/dev",
				contentURL: "https://s3.amazonaws.com/frontm-contentdelivery/botLogos/",
				gmapsApiKey: "AIzaSyA21aJlgn2xSL1bmpSpGqBWWuInLp99m1k",
				paymentGatewayKey: "pk_test_tXlIyqK3p7LCtcF9JGwDgW0C",
				mapboxApiAccessToken:
					"pk.eyJ1IjoiZ2FjaWx1IiwiYSI6ImNqcHh0azRhdTFjbXQzeW8wcW5vdXhlMzkifQ.qPfpVkrWbk-GSBY3uc6z3A",
				gRPCURL: "http://localhost:8080",
			};

		default:
			return {
				envName: "dev",
				baseURL: "https://elbdev.frontm.ai",
				filesAPI: "https://3nf11ibj25.execute-api.us-east-1.amazonaws.com/dev",
				contentURL: "https://s3.amazonaws.com/frontm-contentdelivery/botLogos/",
				soundURL: "https://frontm.ai/sounds/",
				gmapsApiKey: "AIzaSyA21aJlgn2xSL1bmpSpGqBWWuInLp99m1k",
				paymentGatewayKey: "pk_test_tXlIyqK3p7LCtcF9JGwDgW0C",
				mapboxApiAccessToken:
					"pk.eyJ1IjoiZ2FjaWx1IiwiYSI6ImNqcHh0azRhdTFjbXQzeW8wcW5vdXhlMzkifQ.qPfpVkrWbk-GSBY3uc6z3A",
				gRPCURL: "https://grpcdev.frontm.ai:8080",
				gnsDomain: "gnsTestBots",
				aageHempelDomain: "aagehempelEndUser",
			};
	}
};

export default Config("dev");


*/

import devConfig from "./envConfig/dev.config";
import prodConfig from "./envConfig/prod.config";
const env = process.env.REACT_APP_ENV || "development";
const config = {
	development: devConfig,
	production: prodConfig,
};
export default config[env];
