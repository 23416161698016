import React, { Component } from "react";
import Error from "./../Common/Error";
import { validateSignInForm } from "../../Utils/Validator";
import { Link } from "react-router-dom";
import { getAuthCode, unsetAuthCode } from "../../Services/StorageService";
import { activateEnterpriseBots } from "../../Services/BotsService";
import { updateLastLoggedInDomain } from "../../Services/UserService";
import {
	STATION_SATCOM_LANDING,
	THURAYA_LANDING,
	VIKAND_LANDING,
	VIKAND_DIRECT_LANDING,
	INTELL4DESK_LANDING,
	AAGEHEMPEL_LANDING,
	BEACONASSIST_LANDING,
} from "../../Utils/Constants";
const HELPER_MESSAGE =
	"Please contact FrontM support. You have been logged in to default frontM domain";
const FRONTM_DOMAIN = "frontmai";
const ACTIVATE_ENTERPRISE_BOTS_ERROR_CODES = [
	"Invalid code",
	"Code has been already used by the user",
	"Code could not be applied. The code has expired or the maximum limit has been reached",
];
const USED_AUTH_CODE = "used";
class FrontMLogin extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: "",
			password: "",
			error: "",
			showPassword: false,
		};
	}

	componentDidMount() {
		//   window.document.onkeydown = e => {
		//     e = e || window.event;
		//     switch (e.key) {
		//       case "ArrowLeft":
		//       case "ArrowRight":
		//         e.stopPropagation();
		//         break
		//       default:
		//     }
		//   };
		// console.log("on didMOunt ====== FrontmLogin ", this.props);

		if (this.props.error) {
			this.setState({ error: this.props.error });
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.error && this.props.error !== prevState.error) {
			this.setState({ error: this.props.error });
		}
	}

	modifyEmail = (e) => {
		e.stopPropagation();
		let emailValue = e.target.value;
		this.setState({ email: emailValue });
	};
	modifyPassword = (e) => {
		e.stopPropagation();
		this.setState({ password: e.target.value });
	};

	handleErrorMsg = () => {
		console.log("remove error");
		if (this.state.error) {
			this.props.clearLoginError();
			this.props.removeUserLoginError();
			this.setState({ error: null });
		}
	};

	activateEnterpriseBotAndSetLastLoggedInDomain = (authCode) => {
		let message = null;
		return activateEnterpriseBots(authCode).then((activateResponse) => {
			let { content, error, errorMessage } = activateResponse;
			if (error !== 0 || content.length === 0) {
				message = errorMessage
					? errorMessage + ". " + HELPER_MESSAGE
					: HELPER_MESSAGE;
				return updateLastLoggedInDomain(FRONTM_DOMAIN).then(() => {
					if (message) {
						throw new Error(message);
					}
				});
			} else {
				const domains = content;
				let corporateDomainList = domains.filter((domain) => {
					return domain.userDomain !== FRONTM_DOMAIN;
				});
				let corporateDomain =
					corporateDomainList.length > 0
						? corporateDomainList[0].userDomain
						: "";
				if (corporateDomain.length === 0) {
					message = HELPER_MESSAGE;
					corporateDomain = FRONTM_DOMAIN;
				}
				return updateLastLoggedInDomain(corporateDomain).then(() => {
					if (message) {
						throw new Error();
					}
				});
			}
		});
	};

	doFrontMLogin = (e) => {
		e.preventDefault();
		let formData = {
			email: this.state.email,
			password: this.state.password,
		};
		let error = validateSignInForm(formData);
		if (error) {
			this.setState({ error: error });
			return;
		} else {
			const { email, password } = this.state;
			let newEmail = email.trim();
			let message = null;
			if (this.state.error) {
				this.setState({ error: "" });
				this.props.removeUserLoginError();
			}
			console.log("user login in: :::: ", newEmail);
			this.props.doFrontMLogin(newEmail, password, (error) => {
				if (error) {
					console.error("error on login :: :: :: ::", error);
					this.props.onError(error, newEmail, password);
				} else {
					let authCodeObject = getAuthCode();
					let emailFromAuthCode =
						authCodeObject && Object.keys(authCodeObject)[0];
					let authCode = authCodeObject && authCodeObject[emailFromAuthCode];
					if (authCode && emailFromAuthCode === newEmail) {
						this.activateEnterpriseBotAndSetLastLoggedInDomain(authCode)
							.then(() => {
								this.props.onLogin();
							})
							.catch((err) => {
								console.error(
									"error on activateEnterpriseBotAndSetLastLoggedInDomain login :: :: :: ::",
									err
								);
								let errMsg = err ? err.message : "";
								if (
									errMsg &&
									ACTIVATE_ENTERPRISE_BOTS_ERROR_CODES.indexOf(errMsg) !== -1
								) {
									message = errMsg + ". " + HELPER_MESSAGE;
								} else {
									message = HELPER_MESSAGE;
								}
								this.setState({ error: message });
							})
							.finally(() => {
								this.props.onLogin(message); //(message);
							});
					} else {
						this.setState({ error: null });
						this.props.onLogin();
					}
				}
			});
		}
	};
	render() {
		let { email, password, error } = this.state;
		let { landingPath } = this.props;
		//console.log("FML", this.props);
		return (
			<div>
				{error && <Error message={error} />}
				<form role="form" onSubmit={this.doFrontMLogin} noValidate>
					<div className="form-group">
						{(landingPath === THURAYA_LANDING ||
							landingPath === BEACONASSIST_LANDING ||
							landingPath === STATION_SATCOM_LANDING ||
							landingPath === VIKAND_LANDING ||
							landingPath === VIKAND_DIRECT_LANDING ||
							landingPath === INTELL4DESK_LANDING ||
							landingPath === AAGEHEMPEL_LANDING) && (
							<label style={{ color: "#666666" }}> Email </label>
						)}
						<input
							placeholder="Email"
							type="email"
							className="form-control form-content-input"
							onChange={this.modifyEmail}
							value={email}
							autoComplete="username"
						/>
					</div>
					<div
						className="form-group d-flex flex-column align-items-start"
						style={{ position: "relative" }}
					>
						{(landingPath === THURAYA_LANDING ||
							landingPath === BEACONASSIST_LANDING ||
							landingPath === STATION_SATCOM_LANDING ||
							landingPath === VIKAND_LANDING ||
							landingPath === VIKAND_DIRECT_LANDING ||
							landingPath === INTELL4DESK_LANDING ||
							landingPath === AAGEHEMPEL_LANDING) && (
							<label style={{ color: "#666666" }}>Password</label>
						)}
						<input
							placeholder="......"
							type={this.state.showPassword ? "text" : "password"}
							className="form-control form-content-input"
							style={{ backgroundColor: "#f4f4f4" }}
							onChange={this.modifyPassword}
							autoComplete="current-password"
							value={password}
						/>
						{this.state.showPassword ? (
							<i
								className="fa fa-eye"
								onClick={() =>
									this.setState({ showPassword: !this.state.showPassword })
								}
								style={{
									fontSize: "16px",
									color: "#d5d5d5",
									position: "absolute",
									right: "10px",
									bottom: "10px",
									cursor: "pointer",
								}}
							/>
						) : (
							<i
								className="fa fa-eye-slash"
								onClick={() =>
									this.setState({ showPassword: !this.state.showPassword })
								}
								style={{
									fontSize: "16px",
									color: "#d5d5d5",
									position: "absolute",
									right: "10px",
									bottom: "10px",
									cursor: "pointer",
								}}
							/>
						)}
					</div>

					<Link
						to="/resetPassword"
						onClick={() => this.handleErrorMsg()}
						className={`btn btn-text mb-20 ${
							landingPath && landingPath.replace("/", "")
						}-txt`}
						style={{ textTransform: "none" }}
					>
						Forgot Password ?
					</Link>

					<button
						type="submit"
						className={`btn btn-open btn-block btn-icon ${
							landingPath && landingPath.replace("/", "")
						}-btn`}
					>
						Log In
					</button>
				</form>
			</div>
		);
	}
}

export default FrontMLogin;
