import React from "react";

const HomeLogo = () => {
  return (
    <div className="d-flex justify-content-center p-2" style={{ flex: 1 }}>
      <span className="logo-text d-inline-flex align-items-center">
        <img src="img/FrontM Graphic - Logo.jpg" width={200} alt="frontmLogo" />
      </span>
    </div>
  );
};

export default HomeLogo;
