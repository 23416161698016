import {
	RENDER_MESSAGE_TYPE_TABLE,
	RENDER_MESSAGE_TYPE_CONTAINER,
	CHANGE_CONTAINER_SELECTED_TAB,
	RENDER_MESSAGE_TYPE_FORM2,
	RENDER_MESSAGE_TYPE_MENU,
	RENDER_MESSAGE_TYPE_CALENDAR,
	RENDER_MESSAGE_TYPE_HTML,
	RESET_FORM2_FIELDS,
	RESET_CONTAINER_FIELDS,
	CHANGE_CALENDAR,
	FLUSH_APPS,
	REMOVE_APP,
	RENDER_MESSAGE_TYPE_VIDEOCALL,
} from "./types";

let initialState = {};

function NonConversationalReducer(state = initialState, action) {
	switch (action.type) {
		case FLUSH_APPS:
		case REMOVE_APP:
		case RENDER_MESSAGE_TYPE_MENU:
		case RENDER_MESSAGE_TYPE_CONTAINER:
		case CHANGE_CONTAINER_SELECTED_TAB:
		case RENDER_MESSAGE_TYPE_TABLE:
		case RENDER_MESSAGE_TYPE_FORM2:
		case RENDER_MESSAGE_TYPE_VIDEOCALL:
		case RENDER_MESSAGE_TYPE_CALENDAR:
		case RENDER_MESSAGE_TYPE_HTML:
		case RESET_FORM2_FIELDS:
		case RESET_CONTAINER_FIELDS:
		case CHANGE_CALENDAR:
			return {
				...state,
				...action.data,
			};
		default:
			return state;
	}
}

export default NonConversationalReducer;
