import React, { Component } from "react";
import history from "../../Services/History";

class FacebookLoginButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  facebookLogin = () => {
    let FB = window.FB;
    console.log("click on fb", window);
    this.props.clearLoginError();

    if (!FB) return;
    FB.login(this.facebookLoginHandler, {
      scope: "email, public_profile",
      return_scopes: true,
    });
  };

  facebookLoginHandler = (response) => {
    let FB = window.FB;
    if (response.status === "connected") {
      FB.api(
        "/me",
        "get",
        {
          fields: "name,first_name,middle_name,last_name, email",
        },
        (userData) => {
          const user = {
            emailAddress: userData.email,
            givenName: userData.first_name,
            screenName: userData.name ? userData.name.replace(/ /g, "") : "",
            surname: userData.last_name || "",
            userName: userData.name,
            userId: userData.id,
          };
          let accessToken = response.authResponse.accessToken;
          this.props.doFacebookAuth(
            accessToken,
            user.userName,
            user.emailAddress,
            this.props.onLogin
          );
        }
      );
    } else {
      let path = "/voyagervoice";
      let newPathName = localStorage.getItem("pathName");
      if (JSON.parse(newPathName) == path) {
        history.push({
          pathname: path,
        });
      } else {
        this.props.logout();
      }
    }
  };

  render() {
    return (
      <div>
        <a
          onClick={this.facebookLogin}
          className="d-block rounded si-colored-facebook mb-2 mr-1"
        >
          <img
            src="/img/btn-facebook@2x.png"
            alt=""
            className="socialLogin-btn"
          />
        </a>
      </div>
    );
  }
}

export default FacebookLoginButton;
