import PropTypes from "prop-types";
import { addAppNotification } from "../../State/actions/appNotifications";
import { BANNER_TYPE_ERROR, BANNER_TYPE_INFO } from "../../Utils/Constants";
import store from "../../State/configureStore";

const Notify = ({ type, message }) => {
  switch (type) {
    case "error":
      store.dispatch(addAppNotification(message, BANNER_TYPE_ERROR));
      break;
    case "warning":
      store.dispatch(addAppNotification(message, BANNER_TYPE_INFO));
      break;
    case "info":
      store.dispatch(addAppNotification(message, BANNER_TYPE_INFO));
      break;
    case "success":
      store.dispatch(addAppNotification(message, BANNER_TYPE_INFO));
      break;
    default:
      store.dispatch(addAppNotification(message, BANNER_TYPE_INFO));
  }
};

Notify.propTypes = {
  type: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired
};
export default Notify;
