import React, { Component } from "react";
import LoginContainer from "../../../Containers/LoginContainer";
import CorporateLogo from "./CorporateLogo";
import CorporateCustomSignup from "./CorporateCustomSignup";
import LoginWelcomeText from "./LoginWelcomeText";
import {
	STATION_SATCOM_LANDING,
	STATION_SATCOM_LOGIN_BACKGROUND,
	STATION_SATCOM_SIGNUP_BACKGROUND,
	THURAYA_LANDING,
	THURAYA_LOGIN_BACKGROUND,
	THURAYA_SIGNUP_BACKGROUND,
	VOYAGER_LANDING,
	VIKAND_LANDING,
	VIKAND_DIRECT_LANDING,
	INTELL4DESK_LANDING,
	VOYAGER_LOGIN_BACKGROUND,
	VOYAGER_SIGNUP_BACKGROUND,
	VIKAND_SIGNUP_BACKGROUND,
	INTELL4DESK_SIGNUP_BACKGROUND,
	VIKAND_LOGIN_BACKGROUND,
	INTELL4DESK_LOGIN_BACKGROUND,
	AAGEHEMPEL_LANDING,
	AAGEHEMPEL_SIGNUP_BACKGROUND,
	AAGEHEMPEL_LOGIN_BACKGROUND,
	VIKAND_DIRECT_SIGNUP_BACKGROUND,
	BEACONASSIST_LANDING,
	BEACONASSIST_SIGNUP_BACKGROUND,
	BEACONASSIST_LOGIN_BACKGROUND,
} from "../../../Utils/Constants";
import HomeSlidesNavigation from "../../Home/HomeSlidesNavigation";

const Landing = (props) => {
	const { landingPath } = props;
	let signupBackground = "",
		loginBackground = "",
		bgPosition = "";

	switch (landingPath) {
		case VOYAGER_LANDING:
			signupBackground = VOYAGER_SIGNUP_BACKGROUND;
			loginBackground = VOYAGER_LOGIN_BACKGROUND;
			break;
		case VIKAND_LANDING:
			signupBackground = VIKAND_SIGNUP_BACKGROUND;
			loginBackground = VIKAND_LOGIN_BACKGROUND;
			bgPosition = "bottom";
			break;
		case VIKAND_DIRECT_LANDING:
			signupBackground = VIKAND_DIRECT_SIGNUP_BACKGROUND;
			loginBackground = VIKAND_LOGIN_BACKGROUND;
			bgPosition = "center";
			break;
		case INTELL4DESK_LANDING:
			signupBackground = INTELL4DESK_SIGNUP_BACKGROUND;
			loginBackground = INTELL4DESK_LOGIN_BACKGROUND;
			bgPosition = "bottom";
			break;
		case THURAYA_LANDING:
			signupBackground = THURAYA_SIGNUP_BACKGROUND;
			loginBackground = THURAYA_LOGIN_BACKGROUND;
			break;
		case BEACONASSIST_LANDING:
			signupBackground = BEACONASSIST_SIGNUP_BACKGROUND;
			loginBackground = BEACONASSIST_LOGIN_BACKGROUND;
			break;
		case STATION_SATCOM_LANDING:
			signupBackground = STATION_SATCOM_SIGNUP_BACKGROUND;
			loginBackground = STATION_SATCOM_LOGIN_BACKGROUND;
			break;
		case AAGEHEMPEL_LANDING:
			signupBackground = AAGEHEMPEL_SIGNUP_BACKGROUND;
			loginBackground = AAGEHEMPEL_LOGIN_BACKGROUND;
			bgPosition = "center";
			break;
	}
	const noSignUp = [AAGEHEMPEL_LANDING];
	return (
		<div
			className="d-flex flex overflow-hidden"
			style={{ backgroundColor: loginBackground, height: "100%" }}
		>
			<div style={{ flex: 2 }} className="d-flex flex-column">
				<CorporateLogo landingPath={landingPath} />
				<div
					className="d-flex align-items-center flex-column mx-4 p-4"
					style={{ flex: 4 }}
				>
					<LoginWelcomeText landingPath={landingPath} />
					<LoginContainer {...props} />
					{/* {props.fromCarousel && (
						<HomeSlidesNavigation
							previous={props.previous}
							next={props.next}
							activeIndex={props.activeIndex}
							goTo={props.goTo}
							loginPage
						/>
					)} */}
				</div>
			</div>
			<div
				style={{
					flex: 4,
					background: `url(${signupBackground})`,
					backgroundColor: "#444444",
					backgroundSize: "cover",
					height: "100%",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					backgroundPosition: bgPosition,
					boxShadow: "2px 2px 10px -4px rgba(74,74,74,1)",
				}}
			>
				{!noSignUp.includes(landingPath) && (
					<CorporateCustomSignup {...props} />
				)}
			</div>
		</div>
	);
};

export default Landing;
