import axios from "axios/index";
import store from "../State/configureStore";
import Notify from "../Components/ModalMessages/ToastNotif";

const request = axios.create({
	timeout: 100000,
});

function getHeaders() {
	let auth = store.getState().user ? store.getState().user.auth : null;
	if (auth) {
		return {
			sessionId: auth.sessionId,
		};
	}
}

function get(url) {
	let options = {
		method: "get",
		url: url,
		headers: getHeaders(),
	};

	return request(options).then(handleResponse).catch(handleError);
}

function downloadFile(url) {
	let options = {
		method: "get",
		url: url,
		headers: { ...getHeaders() },
		responseType: "blob",
	};

	return request(options).then(handleResponse).catch(handleError);
}

function downloadSignedUrlFile(url) {
	let options = {
		method: "get",
		url: url,
		headers: { ...getHeaders() },
	};

	return request(options).then(handleResponse).catch(handleError);
}

function uploadFile(url, file, contentType, onUploadProgress) {
	let options = {
		method: "post",
		url: url,
		data: file,
		onUploadProgress: onUploadProgress,
	};
	options.headers = { ...getHeaders(), "content-type": contentType };
	console.log(":: :: uploadFile :: ::", options);
	return request(options).then(handleResponse).catch(handleError);
}

export default {
	get,
	downloadFile,
	downloadSignedUrlFile,
	uploadFile,
};

function handleResponse(response) {
	return response.data;
}

function handleError(error) {
	if (!error || !error.response) {
		Notify({
			type: "error",
			message: "Network error has occurred. Please try again",
		});
	} else if (
		error.response.status === 400 ||
		error.response.status === 401 ||
		error.response.status === 422 ||
		error.response.status === 500
	) {
		// Notify({
		//   type: "error",
		//   message: "You've been logged out. Please login again."
		// });
		// store.dispatch(logout());
		console.log("API failure Generic ajax error code  ", error.response.status);
	}

	throw Error;
}
