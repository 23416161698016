import {
	storeAuthData,
	removeAuthData,
	removeFromStorage,
	storeDomainSelected,
	removeDomainSelcted,
	storeLoginState,
	removeLoginState,
	removeOpenForm,
	updateAuthData,
	removeSelectedConversation,
	removeLinkData,
	getSignupPath,
	removePathName,
} from "../../Services/StorageService";
import _ from "lodash";
import history from "./../../Services/History";
import Notify from "../../Components/ModalMessages/ToastNotif";
import AuthServiceClient from "../../Services/Clients/AuthServiceClient";
import UserServiceClient from "../../Services/Clients/UserServiceClient";
import ConversationServiceClient from "../../Services/Clients/ConversationServiceClient";
import { fetchSubscribedChannels, fetchAllChannels } from "./channels";
import { getFavourite, getTimeLine } from "../actions/chats";
import axios from "axios";
import { fetchContacts } from "./contacts";
import {
	fetchWalletBalance,
	getPaginatedCallHistory,
} from "../../Services/VoipServices";

import { getCallHistoryForSelectedContact } from "../../Services/VoipServices";
import { getProfilePhoto } from "../../Services/FilesService";

import Toast from "../../Components/ModalMessages/Toast";
import { FRONTM_LANDING, FRONTM_LOGIN } from "../../Utils/Constants";
import appType from "../../Utils/ApiConfig";
export const USER_LOGGED_IN = "USER_LOGGED_IN";
export const USER_GEOLOCATION_DATA = "USER_GEOLOCATION_DATA";
export const USER_LOG_IN_ERROR = "USER_LOG_IN_ERROR";
export const REMOVE_USER_LOG_IN_ERROR = "REMOVE_USER_LOG_IN_ERROR";
export const BOT_SUBSCRIPTIONS_RECEIVED = "BOT_SUBSCRIPTIONS_RECEIVED";
export const LOGOUT_USER = "LOGOUT_USER";
export const UPDATE_USER = "UPDATE_USER";
export const TERMS_AND_CONDITIONS_ACCEPTED = "TERMS_AND_CONDITIONS_ACCEPTED";
export const DOMAIN_RECEIVED = "DOMAIN_RECEIVED";
export const SELECTED_DOMAIN = "SELECTED_DOMAIN";
export const UPDATE_DOMAIN = "UPDATE_DOMAIN";
export const DOMAIN_SELECTED_IN = "DOMAIN_SELECTED_IN";
export const UPDATE_PHONE_BALANCE = "UPDATE_PHONE_BALANCE";
export const UPDATE_CALL_HISTORY = "UPDATE_CALL_HISTORY";
export const PROFILE_IMAGE = "PROFILE_IMAGE";
export const UPDATE_USER_AUTH_DATA = "UPDATE_USER_AUTH_DATA";
export const UPDATE_LOGIN_METHOD = "UPDATE_LOGIN_METHOD";
export const UPDATE_BANNER = "UPDATE_BANNER";
export const REMOVE_SELECTED_DOMAIN = "REMOVE_SELECTED_DOMAIN";
export const REMOVE_SUBSCRIBED_BOTS = "REMOVE_SUBSCRIBED_BOTS";
export const REMOVE_DOMAINS = "REMOVE_DOMAINS";
export const FORM_UNSAVED = "FORM_UNSAVED";
export const CLEAR_LOGIN_ERROR = "CLEAR_LOGIN_ERROR";
export const UPDATE_LOGGED_IN_USER_CALL_HISTORY =
	"UPDATE_LOGGED_IN_USER_CALL_HISTORY";
export const CLEAR_LOGGED_IN_USER_CALL_HISTORY =
	"CLEAR_LOGGED_IN_USER_CALL_HISTORY";
export const UPDATE_SELECTED_CONTACT_CALL_HISTORY =
	"UPDATE_SELECTED_CONTACT_CALL_HISTORY";
export const SET_REFRESH_ANONYMOUS_SESSION_FLAG =
	"SET_REFRESH_ANONYMOUS_SESSION_FLAG";
export const SHOW_ERROR_PAGE = "SHOW_ERROR_PAGE";
export const RETRY_RECONNECT_COUNT = "RETRY_RECONNECT_COUNT";
export const RESET_RECONNECT_COUNT = "RESET_RECONNECT_COUNT";
export const SET_CALL_QUALITY = "SET_CALL_QUALITY";
export const VOIP_STATUS = "VOIP_STATUS";
export const LICENSE_STATUS = "LICENSE_STATUS";

export function doFrontMLogin(email, password, callback) {
	return (dispatch) => {
		let typeOfApp = appType;
		let doFrontMLoginObj = {
			email,
			password,
			platform: "web",
		};
		if (typeOfApp) {
			doFrontMLoginObj = {
				email,
				password,
				platform: "web",
				appType: typeOfApp,
			};
		}

		return AuthServiceClient.doFrontMLogin(doFrontMLoginObj)
			.then((response) => {
				if (response.success) {
					const { user, sessionId, newUser } = response;
					// console.log("user data ===== ", user);
					dispatch(updateLoginMethod("frontm"));
					dispatch(onSuccessfulAuth(user, sessionId, newUser, false));
					getProfilePhoto(user.userId + "_75x75.png")
						.then((file) => {
							console.log("profile image ", file);
						})
						.catch((err) => {
							console.log("no profile imgae", err);
						});
					dispatch(getAllDomains());
					dispatch(fetchWalletBalanceService());
					callback(null);
				} else {
					throw new Error(response.message);
				}
			})
			.catch((error) => {
				console.error("user j > doFrontMLogin f :: ::", error);
				let message = error.message;
				if (message && message.includes("confirmed")) {
					callback(message);
				} else {
					callback(message);
					dispatch({ type: USER_LOG_IN_ERROR, data: { message } });
				}
				// dispatch({ type: USER_LOG_IN_ERROR, data: { message: error.message } });
			});
	};
}

export function removeUserLoginError() {
	return (dispatch) => {
		dispatch({
			type: REMOVE_USER_LOG_IN_ERROR,
		});
	};
}

export function getGeoLocationData() {
	const ipURL = "https://ipapi.co/json/";
	return (dispatch, getState) => {
		return axios
			.get(ipURL)
			.then((response) => {
				let data = response.data;

				let geoData = {
					country: data.country || "",
					country_calling_code: data.country_calling_code || "",
					country_name: data.country_name || "",
					currency: data.currency || "",
					languages: data.languages.split(",")[0] || "",
				};

				// console.log("**************", geoData);
				dispatch({ type: USER_GEOLOCATION_DATA, data: { ...geoData } });
			})
			.catch((error) => {
				console.log(error);
			});
	};
}

export function getVoipStatus() {
	return (dispatch, getState) => {
		let userIdData = {
			userId: getState()?.user?.user?.userId,
		};
		UserServiceClient.GetVoipStatus(userIdData)
			.then((response) => {
				// console.log("data to voip reponse ", response);

				dispatch({
					type: VOIP_STATUS,
					data: response,
				});
			})
			.catch((error) => {
				console.log("error on voip status", error);
			});
	};
}

export function setSelectedDomain(data) {
	return (dispatch) => {
		dispatch(removeSelectedDomain());
		let newData = _.cloneDeep(data);

		let selectedDomain = {
			selectedDomain: newData.userDomain,
		};

		UserServiceClient.getDomain().then((response) => {
			if (response.domains) {
				let allDomains = response.domains;
				if (data.lockInUsers) {
					//when lockInUsers true dropdown will show the Plus but hiding frontm.ai
					_.remove(allDomains, (domain) => {
						return domain.userDomain === "frontmai";
					});
				}
				dispatch({
					type: DOMAIN_RECEIVED,
					data: { domains: response },
				});
			}
		});

		dispatch({
			type: SELECTED_DOMAIN,
			data: { selectedDomain: newData },
		});
		dispatch(getTimeLine("domainChange"));
		dispatch(getFavourite(selectedDomain));
		dispatch(fetchContacts(selectedDomain));
		dispatch(fetchBotSubscriptions(selectedDomain));
		dispatch(fetchSubscribedChannels(selectedDomain));
		dispatch(fetchAllChannels(selectedDomain));
	};
}

// export function updateLastLoggedInDomain(data) {
//   return (dispatch, getState) => {
//     return UserServiceClient.updateLastLoggedInDomain(data).then(response => {
//       console.log("updateLastLoggedInDomain", response);
//       dispatch({
//         type: LAST_SELECTED_DOMAIN,
//         data: {lastSelectedDomain}
//       })
//     });
//   };
// }

export function isCorporateLicenseValid(isValid) {
	return {
		type: LICENSE_STATUS,
		data: isValid,
	};
}

export async function selectedUserDomain() {
	let isLastLogin = false;
	let lastLoginDomain = null;
	await UserServiceClient.getDomain().then((response) => {
		response.domains.forEach((elem) => {
			if (elem.lastLoggedIn) {
				isLastLogin = true;
				lastLoginDomain = elem;
			}
		});
	});

	return lastLoginDomain;
}

export function getAllDomains() {
	return (dispatch) => {
		return UserServiceClient.getDomain().then((reponse) => {
			console.log("getAllDomain going in this =====", reponse);
			// dispatch(removeSelectedDomain());
			let isLastLogin = false;
			let lastLoginDomain = null;

			reponse.domains.forEach((elem) => {
				if (elem && elem.lastLoggedIn) {
					isLastLogin = true;
					lastLoginDomain = elem;
				}
			});

			let lastLoggedInDomain = _.cloneDeep(lastLoginDomain);
			let responseDomain = _.cloneDeep(reponse.domains[0]);

			if (lastLoggedInDomain && lastLoggedInDomain.lockInUsers) {
				//when lockInUsers true dropdown will show the Plus but hiding frontm.ai
				let allDomains = _.cloneDeep(reponse.domains);
				_.remove(allDomains, (domain) => {
					return domain.userDomain === "frontmai";
				});
				reponse.domains = allDomains;
				console.log("remove frontm domain", allDomains, reponse);
			}

			if (isLastLogin) {
				dispatch({
					type: SELECTED_DOMAIN,
					data: { selectedDomain: lastLoggedInDomain },
				});
			} else {
				dispatch({
					type: SELECTED_DOMAIN,
					data: { selectedDomain: responseDomain },
				});
			}
			dispatch(onFirstSelectDomain(lastLoggedInDomain));
			dispatch(getVoipStatus());
			dispatch({
				type: DOMAIN_RECEIVED,
				data: { domains: reponse },
			});
		});
	};
}

export function setAllDomanins(data) {
	return (dispatch, getState) => {
		dispatch({
			type: DOMAIN_RECEIVED,
			data: { domains: data },
		});
	};
}

// export function doFacebookAuth(user, token, callback) {
//   return (dispatch, getState) => {
//     doAuth(user, token, "facebook")
//       .then(response => {
//         const {
//           creds,
//           user,
//           longTermToken,
//           error,
//           sessionId,
//           newUser
//         } = response;
//         if (error) {
//           throw new Error("Facebook login failed");
//         }

//         dispatch(
//           onSuccessfulAuth(
//             user,
//             creds,
//             null,
//             longTermToken,
//             "facebook",
//             sessionId,
//             newUser
//           )
//         );
//         callback(null);
//       })
//       .catch(error => {
//         Notify({ type: "error", message: error.message });
//       });
//   };
// }

export function doAnonymousAuth(urlDomain, botId, callback) {
	return (dispatch, getState) => {
		let typeOfApp = appType;
		let createAnonymousAccessObj = {};
		if (typeOfApp) {
			createAnonymousAccessObj = {
				urlDomain: `${urlDomain}/${botId}`,
				botId: botId,
				platform: "web",
				appType: typeOfApp,
			};
		} else {
			createAnonymousAccessObj = {
				urlDomain: `${urlDomain}/${botId}`,
				botId: botId,
				platform: "web",
			};
		}

		return AuthServiceClient.createAnonymousAccess(createAnonymousAccessObj)
			.then((response) => {
				console.log("anonymous user created :: ", response);
				const { user, sessionId, newUser } = response;
				dispatch(onSuccessfulAuth(user, sessionId, newUser, true));
				callback && callback(null);
			})
			.catch((e) => {
				let message = e.message;
				console.log("doAnonymousAuth error :: ", message);
				dispatch(showErrorPage());
				// Toast({
				//   type: "error",
				//   message: message,
				//   autoClose: 4000
				// });
				callback && callback(message);
			});
	};
}

export function showErrorPage() {
	return {
		type: SHOW_ERROR_PAGE,
	};
}

export function refreshAnonymousUserSession(callback) {
	console.log("refresh anonymous user session");
	return (dispatch, getState) => {
		dispatch(setFlagForRefreshAnonymousSessionCall(true));
		let typeOfApp = appType;
		let dataObj = {};
		if (typeOfApp) {
			dataObj = {
				appType: typeOfApp,
			};
		}
		return AuthServiceClient.refreshAnonymousUserSession(dataObj)
			.then((response) => {
				console.log("anonymous user session refreshed ");
				updateAuthData();
				callback && callback(null);
			})
			.catch((error) => {
				let message = error.message;
				console.log(
					"error while anonymous user session refresh :: ",
					JSON.stringify(error.message)
				);
				Toast({
					type: "error",
					message: message,
					autoClose: 4000,
				});
				callback && callback(message);
			})
			.finally(() => {
				dispatch(setFlagForRefreshAnonymousSessionCall(false));
			});
	};
}

export function doFacebookAuth(code, userName, emailAddress, callback) {
	return (dispatch, getState) => {
		return AuthServiceClient.doFacebookLogin(code, userName, emailAddress)
			.then((response) => {
				console.log("facebook login ", response);

				dispatch(updateLoginMethod("facebook"));
				const { user, sessionId, newUser } = response;
				dispatch(onSuccessfulAuth(user, sessionId, newUser, false));
				dispatch(getAllDomains());
				callback(null);
			})
			.catch((e) => {
				Notify({ type: "error", message: e.message });
			});
	};
}

export function doGoogleAuth(code, callback) {
	return (dispatch, getState) => {
		return AuthServiceClient.doGoogleLogin(code)
			.then((response) => {
				console.log("google login ", response);
				dispatch(updateLoginMethod("google"));
				const { user, sessionId, newUser } = response;
				dispatch(onSuccessfulAuth(user, sessionId, newUser, false));
				dispatch(getAllDomains());
				callback(null);
			})
			.catch((e) => {
				Notify({ type: "error", message: e.message });
			});
	};
}

export function updateAuthUser(data) {
	return (dispatch, getState) => {
		dispatch({
			type: UPDATE_USER_AUTH_DATA,
			data: { newUser: data },
		});
	};
}
//
// export function updateUserSessionCreationTime() {
//   updateAuthData();
//   return {
//     type: UPDATE_USER_SESSION_CREATION_TIME,
//     data: dayjs()
//   };
// }

export function updateLoginMethod(data) {
	storeLoginState(data);
	return (dispatch, getState) => {
		dispatch({
			type: UPDATE_LOGIN_METHOD,
			data: {
				data,
			},
		});
	};
}

function onFirstSelectDomain(data) {
	storeDomainSelected(data);
	return {
		type: DOMAIN_SELECTED_IN,
		data: {
			selectedDomain: data,
		},
	};
}
export function onSelectDomain(data) {
	return {
		type: DOMAIN_SELECTED_IN,
		data: {
			selectedDomain: data,
		},
	};
}

export function setFlagForRefreshAnonymousSessionCall(status) {
	return {
		type: SET_REFRESH_ANONYMOUS_SESSION_FLAG,
		data: status,
	};
}

export function removeSelectedDomain() {
	return {
		type: REMOVE_SELECTED_DOMAIN,
	};
}

export function removeSubscribedBotList() {
	return {
		type: REMOVE_SUBSCRIBED_BOTS,
	};
}
export function removeDomains() {
	return {
		type: REMOVE_DOMAINS,
	};
}

function onSuccessfulAuth(user, sessionId, newUser, isAnonymousUser) {
	storeAuthData({
		user,
		auth: {
			sessionId,
			newUser,
		},
		isAnonymousUser: isAnonymousUser,
	});

	return {
		type: USER_LOGGED_IN,
		data: {
			user,
			auth: {
				sessionId,
				newUser,
			},
			isAnonymousUser: isAnonymousUser,
		},
	};
}

export function hydrateUserData(data) {
	// console.log("[hydrateUserData] data :: ", data);
	return {
		type: USER_LOGGED_IN,
		data: data,
	};
}

export function fetchBotSubscriptions(domain) {
	console.log("fetch ====>>>>>", domain);
	return (dispatch) => {
		UserServiceClient.getBotSubscriptionsInfo(domain).then((response) => {
			console.log("fetchBotSubscriptions ====>>>>>", response);
			if (response.error) {
				dispatch({
					type: BOT_SUBSCRIPTIONS_RECEIVED,
					data: { botSubscriptions: [] },
				});
				return;
			}

			dispatch({
				type: BOT_SUBSCRIPTIONS_RECEIVED,
				data: { botSubscriptions: response.content.subscribed },
			});
		});
		// UserServiceClient.fetchBotSubscriptions(domain).then((data) => {
		// 	if (data.error) {
		// 		dispatch({
		// 			type: BOT_SUBSCRIPTIONS_RECEIVED,
		// 			data: { botSubscriptions: [] },
		// 		});
		// 		return;
		// 	}
		// 	const botIds =
		// 		data.content && data.content.subscribed ? data.content.subscribed : [];

		// 	return ConversationServiceClient.getCatalog({
		// 		isWebRequest: true,
		// 	}).then((response) => {
		// 		parseCatalogResponse(response, botIds, dispatch);
		// 	});
		// });
	};
}

export function fetchCatalog() {
	return (dispatch) => {
		ConversationServiceClient.getCatalog({ isWebRequest: true }).then(
			(response) => {
				let subscribedBots = response.bots || [];
				console.log("fetchCatalog ====>>>>>", response);
				dispatch({
					type: BOT_SUBSCRIPTIONS_RECEIVED,
					data: { botSubscriptions: subscribedBots },
				});
			}
		);
	};
}

export function unsavedForm(bol) {
	return {
		type: FORM_UNSAVED,
		data: bol,
	};
}

function parseCatalogResponse(data, botIds, dispatch) {
	let bots = data.bots || [];
	const subscribedBots = bots.filter((bot) => botIds.includes(bot.botId));
	dispatch({
		type: BOT_SUBSCRIPTIONS_RECEIVED,
		data: { botSubscriptions: subscribedBots },
	});
}

export function logout() {
	let loginPath = getSignupPath();
	// let newPathName = localStorage.getItem("pathName");

	if (loginPath && loginPath.length) {
		if (loginPath === FRONTM_LANDING || loginPath === FRONTM_LOGIN) {
			removePathName();
			history.push("/login");
		}
		history.push({
			pathname: loginPath,
		});
	} else {
		removePathName();
		history.push("/login");
	}

	return (dispatch, getState) => {
		let checkFormUnsaved = getState().user.formUnsaved;
		if (!checkFormUnsaved) {
			window.document.title = "FrontM Platform";
			clearTimeout(window.interval);
			removeAuthData();
			removeOpenForm();
			removeDomainSelcted();
			removeFromStorage();
			removeLoginState();
			removeLinkData();
			removeSelectedConversation();
			dispatch({ type: LOGOUT_USER });
			dispatch(removeSelectedDomain());
			dispatch(removeDomains());
		}
	};
}

export function updateUserProfile(data) {
	return {
		type: UPDATE_USER,
		data: { user: data },
	};
}
export function updateSelectedDomian(data) {
	return {
		type: UPDATE_DOMAIN,
		data: {
			selectedDomain: data,
		},
	};
}

export function closeBanner() {
	return {
		type: UPDATE_BANNER,
		data: {
			bannerType: null,
			message: null,
		},
	};
}

export function sendMsgForBanner(bannerType, msg) {
	console.log("send the to banner ", bannerType, msg);
	return {
		type: UPDATE_BANNER,
		data: {
			bannerType: bannerType,
			message: msg,
		},
	};
}

export function updateTnC(accepted) {
	return (dispatch) => {
		UserServiceClient.updateTnC(accepted).then((data) => {
			if (!data.error || data.error == 0) {
				dispatch({
					type: TERMS_AND_CONDITIONS_ACCEPTED,
					data: { accepted },
				});
			}
		});
	};
}

export const fetchWalletBalanceService = () => (dispatch) => {
	fetchWalletBalance()
		.then((balance) => {
			dispatch(
				setPhoneBalance({
					balance,
				})
			);
		})
		.catch((error) => {
			console.log("Cannot fetch wallet balance", error);
		});
};

// export const setCallHistory = () => dispatch => {
//   getCallHistory()
//     .then(callHistory => {
//       dispatch({
//         type: UPDATE_CALL_HISTORY,
//         payload: {
//           callHistory
//         }
//       });
//     })
//     .catch(error => {
//       console.log("Cannot fetch Call History");
//     });
// };

export const setCallHistory = (selectedContactId) => (dispatch) => {
	getCallHistoryForSelectedContact(selectedContactId).then((callHistory) => {
		// console.log("setCallHistory", callHistory, selectedContactId);
		dispatch({
			type: UPDATE_SELECTED_CONTACT_CALL_HISTORY,
			payload: {
				callHistory,
				selectedContactId,
			},
		});
	});
};

export const getLoggedInUserCallHistory = (startTime) => (
	dispatch,
	getState
) => {
	const timeStamp =
		startTime ||
		getState().user.timeStampOfLastRecordInExCallHistoryForLoggedInUser;
	getPaginatedCallHistory(timeStamp)
		.then((callHistory) => {
			console.log("call history after cutting call ====", callHistory);
			dispatch({
				type: UPDATE_LOGGED_IN_USER_CALL_HISTORY,
				payload: {
					records: callHistory.records,
					moreRecordsExist: callHistory.moreRecordsExist,
				},
			});
		})
		.catch((error) => {
			console.log("Cannot fetch logged in user Call History", error);
		});
};

export const clearLoginError = () => {
	return {
		type: CLEAR_LOGIN_ERROR,
		payload: "",
	};
};

export const setReconnectCount = () => (dispatch) => {
	dispatch({
		type: RETRY_RECONNECT_COUNT,
	});
};
export const resetReconnectCount = () => (dispatch) => {
	dispatch({
		type: RESET_RECONNECT_COUNT,
	});
};
export const clearLoggedInUserCallHistory = () => {
	return {
		type: CLEAR_LOGGED_IN_USER_CALL_HISTORY,
	};
};
export const setPhoneBalance = (payload) => ({
	type: UPDATE_PHONE_BALANCE,
	payload,
});

export const setCallQuality = (payload) => ({
	type: SET_CALL_QUALITY,
	payload,
});

export const setUserProfileImage = (payload) => ({
	type: PROFILE_IMAGE,
	payload,
});
