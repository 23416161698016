import React, { Component } from "react";
import { Link } from "react-router-dom";
import GoogleLoginButton from "./GoogleLoginButton";
import FrontMLogin from "./FrontMLogin";
import FacebookLoginButton from "./FacebookLoginButton";

import {
	AAGEHEMPEL_LANDING,
	AAGEHEMPEL_TITLE,
	FRONTM_TITLE,
	ONSHIP_LANDING,
	ONSHIP_TITLE,
	BEACONASSIST_LANDING,
	BEACONASSIST_TITLE,
} from "../../Utils/Constants";
import history from "../../Services/History";

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			corporateSignup: false,
			signUpPath: "/signup",
			showSignUpLink: [AAGEHEMPEL_LANDING],
		};
	}

	componentDidMount() {
		let state = this.props && this.props.location && this.props.location.state;
		if (state && state.corporateSignup) {
			this.setState({ corporateSignup: true });
		} else {
			this.setState({ corporateSignup: false });
		}
		switch (this.props.landingPath) {
			case AAGEHEMPEL_LANDING:
				window.document.title = AAGEHEMPEL_TITLE;
				break;
			case ONSHIP_LANDING:
				window.document.title = ONSHIP_TITLE;
				break;
			case BEACONASSIST_LANDING:
				window.document.title = BEACONASSIST_TITLE;
				break;
			default:
				window.document.title = FRONTM_TITLE;
				break;
		}
		state && delete state.corporateSignup;
		state && history.replace({ state: { ...state } });

		if (this.state.showSignUpLink.includes(this.props.landingPath)) {
			this.setState({
				signUpPath: this.props.landingPath + "/signup",
			});
		}
	}

	showSocialLogin = () => {
		if (
			this.props.landingPath === "/vikand" ||
			this.props.landingPath === "/beaconassist" ||
			this.props.landingPath === "/vikanddirect" ||
			this.props.landingPath === "/aagehempel"
		) {
			return null;
		} else
			return (
				<div>
					<div className="text-center sep-white mb-2">
						<span style={{ color: "inherit" }}>Or log in with</span>
					</div>
					<div className="d-flex justify-content-around">
						<FacebookLoginButton {...this.props} />
						<GoogleLoginButton {...this.props} />
					</div>
				</div>
			);
	};

	render() {
		// console.log("login for vikand ", this.props);

		return (
			<div
				className="d-flex flex-column"
				style={{
					width:
						(this.props.landingPath === "/vikand" ||
							this.props.landingPath === "/beaconassist" ||
							this.props.landingPath === "/vikanddirect" ||
							this.props.landingPath === "/intelli4desks" ||
							this.props.landingPath == "/aagehempel") &&
						"300px",
				}}
			>
				<div>
					<div className="px-4 py-2">
						<FrontMLogin {...this.props} />
					</div>
					<div className="p-4 flex">
						{this.showSocialLogin()}
						{(!this.props.corporateSignup ||
							this.state.showSignUpLink.includes(this.props.landingPath)) && (
							<div className="text-center mt-4 pt-4">
								<Link
									to={{
										pathname: this.state.signUpPath,
										state: "flush",
									}}
									className="btn btn-install"
									style={{ textTransform: "none" }}
								>
									You don’t have an account? Sign up{" "}
									<i className="icon-arrow-right" />
								</Link>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}

export default Login;
