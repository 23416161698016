import React from "react";
import { Route, Switch, Router, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.min.css";
import "./Main.css";
import { ToastContainer } from "react-toastify";
import store from "../../State/configureStore";
import Spinner from "../Spinner";
import history from "../../Services/History";
import HomeContainer from "../../Containers/HomeContainer";
import ReactGA from "react-ga";
import NonConversationalLoader from "../NonConversationalLoader";
import ErrorPageContainer from "../../Containers/ErrorPageContainer";
import Error404 from "../ErrorPages/Error404";
import ProtectedRoute from "../ProtectedRoute";
import ReactBreakpoints from "react-breakpoints";
import Loadable from "react-loadable";
import { setSignupPath } from "../../Services/StorageService";
import {
	AAGEHEMPEL_SIGNUP_LANDING,
	AAGEHEMPEL_LANDING,
} from "../../Utils/Constants";

const breakpoints = {
	mobile: 320,
	mobileLandscape: 480,
	tablet: 768,
	tabletLandscape: 1024,
	desktop: 1200,
	desktopLarge: 1500,
	desktopWide: 1920,
};

const LoginPage = Loadable({
	loader: () => import("../Home/LoginPage"),
	loading: Spinner,
	delay: 500,
});

const PingStatusPage = Loadable({
	loader: () => import("../Home/PingStatusPage"),
	loading: Spinner,
	delay: 500,
});

const SignupView = Loadable({
	loader: () => import("../Signup/SignupView"),
	loading: Spinner,
	delay: 500,
});

const SendCodeAgain = Loadable({
	loader: () => import("../Signup/SendCodeAgain"),
	loading: Spinner,
	delay: 500,
});

const ResetPassword = Loadable({
	loader: () => import("../ResetPassword/ResetPassword"),
	loading: Spinner,
	delay: 500,
});

const ResetConfirmPassword = Loadable({
	loader: () => import("../ResetPassword/ResetConfirmPassword"),
	loading: Spinner,
	delay: 500,
});

const UserVerificationView = Loadable({
	loader: () => import("../Signup/UserVerificationView"),
	loading: Spinner,
	delay: 500,
});

const LinkableMessage = Loadable({
	loader: () => import("../Links/LinkableMessage"),
	loading: Spinner,
	delay: 500,
});

const App = Loadable({
	loader: () => import("../App/App"),
	loading: Spinner,
	delay: 500,
});

if (window?.location?.pathname) {
	const pathArray = [
		"/login",
		"/thuraya",
		"/vikand",
		"/vikanddirect",
		"/intelli4desks",
		"/aagehempel",
		"/voyagervoice",
		"/stationsatcom",
	];
	if (window.location.pathname === "/") {
		setSignupPath("/login");
	} else {
		let pathIndex;
		pathIndex = pathArray.findIndex(
			(elem) => elem === window.location.pathname
		);
		if (pathIndex !== -1) {
			if (window.location.pathname === AAGEHEMPEL_SIGNUP_LANDING) {
				setSignupPath(AAGEHEMPEL_LANDING);
			} else {
				setSignupPath(window.location.pathname);
			}
		}
	}
}

if (window.location.hostname.search("frontm.ai") !== -1) {
	ReactGA.initialize("UA-37782731-6");
	history.listen((location) => {
		ReactGA.pageview(location.pathname + location.search);
	});
}

const Main = () => {
	return (
		<Provider store={store}>
			<ReactBreakpoints breakpoints={breakpoints}>
				<Router history={history}>
					<div className="App-wrapper">
						<Switch>
							<Route exact path="/login" component={LoginPage} />
							<Route exact path="/status" component={PingStatusPage} />
							<ProtectedRoute
								exact
								path="/login/:destination"
								component={LoginPage}
							/>
							<ProtectedRoute exact path="/signup" component={SignupView} />
							<ProtectedRoute
								exact
								path="/voyagervoice"
								component={SignupView}
							/>
							<ProtectedRoute exact path="/vikand" component={SignupView} />
							<ProtectedRoute
								exact
								path="/vikanddirect"
								component={HomeContainer}
							/>
							<ProtectedRoute
								exact
								path="/intelli4desks"
								component={SignupView}
							/>
							<ProtectedRoute exact path="/thuraya" component={SignupView} />
							<ProtectedRoute
								exact
								path="/beaconassist"
								component={SignupView}
							/>
							<ProtectedRoute exact path="/aagehempel" component={SignupView} />
							<ProtectedRoute
								exact
								path="/aagehempel/signup"
								component={SignupView}
							/>
							<ProtectedRoute
								exact
								path="/resetPassword"
								component={ResetPassword}
							/>
							<ProtectedRoute
								exact
								path="/:domainName/resetPassword"
								component={ResetPassword}
							/>
							<ProtectedRoute
								exact
								path="/sendcode"
								component={SendCodeAgain}
							/>
							<ProtectedRoute
								exact
								path="/resetConfirmPassword"
								component={ResetConfirmPassword}
							/>
							<ProtectedRoute
								exact
								path="/verify"
								component={UserVerificationView}
							/>
							<Route
								exact
								path="/messages/:type/:botId"
								component={LinkableMessage}
							/>
							<Route
								exact
								path="/messages/:type/:botId/r/:message"
								component={LinkableMessage}
							/>
							<Route
								exact
								path="/messages/:type/:botId/s/:message"
								component={LinkableMessage}
							/>
							<Route path="/app" component={App} />
							<Route exact path="/" component={HomeContainer} />

							<Route path="/*" component={Error404} />
						</Switch>
						<ToastContainer />
						<Spinner />
						<NonConversationalLoader />
						<ErrorPageContainer />
					</div>
				</Router>
			</ReactBreakpoints>
		</Provider>
	);
};

export default Main;
