import React from "react";
import ReactDOM from "react-dom";
import Main from "./Components/Main/Main";
import register from "./registerServiceWorker";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import "./styles/css/style.css";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#2DC0EF",
      contrastText: "#FFFFFF",
    },
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Main />
  </ThemeProvider>,
  document.getElementById("root")
);
register();
