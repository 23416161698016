const { getSignupPath } = require("../Services/StorageService");
let landingPath = window.location.pathname;

if (landingPath === "/verify" || landingPath.includes("/app")) {
	if (getSignupPath()) {
		landingPath = getSignupPath();
	}
}

let appType = null;

const apiChangeArray = [
	{
		path: "/aagehempel",
		appType: "aagehempel",
	},
	{
		path: "/aagehempel/signup",
		appType: "aagehempel",
	},
	{
		path: "/vikand",
		appType: "vikand",
	},
	{
		path: "/vikanddirect",
		appType: "vikand",
	},
	{
		path: "/beaconassist",
		appType: "macn",
	},
];

let getIndex = null;

if (landingPath) {
	getIndex = apiChangeArray.findIndex((elem) => elem.path === landingPath);
}

if (getIndex !== null && getIndex !== -1) {
	appType = apiChangeArray[getIndex].appType;
}

export default appType;
