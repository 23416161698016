import store from "../../State/configureStore";
import { showSpinner, hideSpinner } from "../../State/actions/spinner";
import { logout } from "../../State/actions/user";
import {
	setBannerVisibility,
	setCounter,
} from "../../State/actions/offlineBanner";
import Config from "./../../Utils/Config";
import { returnStatement } from "@babel/types";
const Buffer = require("buffer/").Buffer;

export default class ServiceClientUtils {
	static requestInterceptor(noSpinner) {
		if (!noSpinner) {
			store.dispatch(showSpinner());
		}
	}

	static handleResponse(response) {
		if (!response) {
			console.log("%cERRROR NO RESPONSE", "color:red;font-size:15px", response);
			return;
			// throw new Error();
		}

		if (
			(Object.prototype.hasOwnProperty.call(response, "success") &&
				!response.success) ||
			(Object.prototype.hasOwnProperty.call(response, "error") &&
				response.error != 0)
		) {
			console.error("error log ", response);

			if (
				response.errorCode &&
				response.errorCode === "InvalidParameterException"
			) {
				response.message = "Invalid Email";
			}

			if (response.message === "Username/client id combination not found.") {
				response.message = "The email address doesn't exist";
			}

			if (response.error === 98) {
				console.log("Sourav Logging:::: Error", response);
				if (
					response.errorMessage &&
					response.content &&
					response.content.length === 0
				) {
					let errorRespnse = {
						errorCode: response.error,
						errorMessage: response.errorMessage,
					};
					throw errorRespnse;
				}
			}
		}

		// if (!response.success) {
		//   if ((response.error && response.error != 0) || response.message) {
		//     // ServiceClientUtils.handleError(response.message);
		//     throw new Error(response.message);
		//   }
		// }

		store.dispatch(hideSpinner());
		return response;
	}

	static handleError(error, url) {
		store.dispatch(hideSpinner());
		if (error && error.code === 14) {
			console.log("API ERROR @ 14: ", error.message);
			store.dispatch(setBannerVisibility(true));
			store.dispatch(setCounter(5));
		} else {
			let message =
				error && error.message
					? error.message
					: "Server error has occurred. Please try again";
			if (Config.envName === "dev") {
				message += ". URL= " + url;
			}
			let auth = store.getState().user ? store.getState().user.auth : null;
			if (!auth) {
				return;
			}
			console.log("API ERROR ======: ", message);
			//hiding the errors from users
			// Notify({
			//   type: "error",
			//   message
			// });
		}

		if (error.message === "UNAUTHORIZED") {
			console.log("API ERROR @ UNAUTHORIZED: ", error.message);
			store.dispatch(logout());
		}
		throw Error;
	}

	static parseBytesContent(content) {
		if (content && content.length > 0) {
			return JSON.parse(Buffer.from(content, "base64"));
			// return JSON.parse(String.fromCharCode.apply(null, content));
		}
		return content;
	}
}
