import { combineReducers } from "redux";
import NonConversationalReducer from "../Containers/NonConversational/Store/NonConversationalReducer";
import {
	SnackbarReducer,
	NotificationReducer,
	CallNotificationReducer,
} from "./Notification/NotificationRedux";

const v2Store = combineReducers({
	NonConversational: NonConversationalReducer,
	Snackbar: SnackbarReducer,
	Notification: NotificationReducer,
	CallNotification: CallNotificationReducer,
});

export default v2Store;
