import React, { Component } from "react";
import HomeCarousel from "./HomeCarousel";
import { getAuthData } from "../../Services/StorageService";
import CookiePolicy from "../App/CookiePolicy";

class Home extends Component {
	componentDidMount() {
		let { hydrateUserData, user } = this.props;
		if (!user) {
			let storedUser = getAuthData();
			if (storedUser) {
				hydrateUserData(storedUser);
				this.props.history.push("/app/home");
			}
		}
	}
	render() {
		return (
			<React.Fragment>
				<HomeCarousel history={this.props.history} />
				{!this.props.cookiePolicyAccepted && (
					<div
						style={{
							position: "fixed",
							bottom: 0,
							width: "100%",
						}}
					>
						<CookiePolicy acceptCookiePolicy={this.props.acceptCookiePolicy} />
					</div>
				)}
			</React.Fragment>
		);
	}
}

export default Home;
