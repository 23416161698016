import { MessageTypeConstants } from "../../../../Services/Message";
import {
	RENDER_MESSAGE_TYPE_TABLE,
	RENDER_MESSAGE_TYPE_CONTAINER,
	CHANGE_CONTAINER_SELECTED_TAB,
	RENDER_MESSAGE_TYPE_FORM2,
	RENDER_MESSAGE_TYPE_MENU,
	RENDER_MESSAGE_TYPE_CALENDAR,
	RENDER_MESSAGE_TYPE_HTML,
	CHANGE_CALENDAR,
	RESET_FORM2_FIELDS,
	RESET_CONTAINER_FIELDS,
	FLUSH_APPS,
	REMOVE_APP,
	RENDER_MESSAGE_TYPE_VIDEOCALL,
} from "./types";
import store from "../../../../State/configureStore";
import _ from "lodash";

import {
	showSnackbarV2,
	handleMessageTypeNotification,
} from "../../../Store/Notification/NotificationAction";

export const v2HandleNonConversationalServerMessages = (
	conversationId,
	message
) => (dispatch) => {
	switch (message.messageType) {
		case MessageTypeConstants.MESSAGE_TYPE_MENU:
			dispatch(handleMessageTypeMenu(conversationId, message));
			break;
		case MessageTypeConstants.MESSAGE_TYPE_TABLE:
			dispatch(handleMessageTypeTable(conversationId, message));
			break;
		case MessageTypeConstants.MESSAGE_TYPE_CONTAINER:
			dispatch(handleMessageTypeContainer(conversationId, message));
			break;
		case MessageTypeConstants.MESSAGE_TYPE_FORM2:
			dispatch(handleMessageTypeForm2(conversationId, message));
			break;
		case MessageTypeConstants.MESSAGE_TYPE_CALENDAR:
			dispatch(handleMessageTypeCalendar(conversationId, message));
			break;
		case MessageTypeConstants.MESSAGE_TYPE_VIDEO_CALL:
			// if (
			// 	message.contentType === 600 &&
			// 	message.message.action === "startVideo"
			// ) {
			// 	dispatch(handleMessageTypeVideoCall(conversationId, message));
			// } else if (
			// 	message.contentType === 600 &&
			// 	message.message.action === "ringStart"
			// ) {
			// 	dispatch(handleMessageTypeNotification(conversationId, message));
			// }
			if (message.contentType === 600) {
				dispatch(handleMessageTypeNotification(conversationId, message));
			}
			break;
		case MessageTypeConstants.MESSAGE_TYPE_HTML:
			dispatch(handleMessageTypeHtml(conversationId, message));
			break;
		case MessageTypeConstants.MESSAGE_TYPE_STD_NOTIFICATION:
		case MessageTypeConstants.MESSAGE_TYPE_CRITICAL_NOTIFICATION:
		case MessageTypeConstants.MESSAGE_TYPE_AUTHORIZATION_REQUEST:
			dispatch(handleMessageTypeNotification(conversationId, message));
			break;
	}
};

export const handleMessageTypeMenu = (conversationId, message) => (
	dispatch
) => {
	// assign custom route view
	let customMessage = _.cloneDeep(message);
	let routesWithId = {};
	let routesWithLabel = {};
	let menuEntriesId = [];
	function createRoutes(menu, parents = "") {
		for (let i = 0; i < menu.length; i++) {
			if (menu[i] && menu[i].id) {
				let route = menu[i] && menu[i].id && parents + `/${menu[i].id}`;
				routesWithId[menu[i].id] = route;
				routesWithLabel[route] = menu[i].label;
				menuEntriesId.push(menu[i].id);
				if (menu[i].submenu) {
					createRoutes(menu[i].submenu, route);
				}
			}
		}
	}
	createRoutes(customMessage?.message?.menuEntries);
	customMessage = {
		...customMessage,
		routesWithId,
		routesWithLabel,
		menuEntriesId,
	};
	if (
		store.getState().v2.NonConversational &&
		!_.isEmpty(store.getState().v2.NonConversational)
	) {
		let NonConversationalData = store.getState().v2.NonConversational[
			conversationId
		];

		if (NonConversationalData && !_.isEmpty(NonConversationalData)) {
			NonConversationalData.forEach((item, i) => {
				if (
					item?.options?.controlId &&
					customMessage?.options?.controlId &&
					item?.options?.controlId === customMessage?.options?.controlId
				) {
					NonConversationalData.splice(i, 1);
				}
			});
			NonConversationalData.push(customMessage);
		} else {
			NonConversationalData = [customMessage];
		}

		dispatch({
			type: RENDER_MESSAGE_TYPE_MENU,
			data: {
				[conversationId]: NonConversationalData,
			},
		});
	} else {
		dispatch({
			type: RENDER_MESSAGE_TYPE_MENU,
			data: {
				[conversationId]: [customMessage],
			},
		});
	}
};

export const handleMessageTypeVideoCall = (conversationId, message) => (
	dispatch
) => {
	if (
		store.getState().v2.NonConversational &&
		!_.isEmpty(store.getState().v2.NonConversational)
	) {
		let NonConversationalData = store.getState().v2.NonConversational[
			conversationId
		];

		if (NonConversationalData && !_.isEmpty(NonConversationalData)) {
			NonConversationalData.push(message);
		} else {
			NonConversationalData = [message];
		}
		dispatch({
			type: RENDER_MESSAGE_TYPE_VIDEOCALL,
			data: {
				[conversationId]: NonConversationalData,
			},
		});
	} else {
		dispatch({
			type: RENDER_MESSAGE_TYPE_VIDEOCALL,
			data: {
				[conversationId]: [message],
			},
		});
	}
};

export const handleMessageTypeTable = (conversationId, message) => (
	dispatch
) => {
	if (
		store.getState().v2.NonConversational &&
		!_.isEmpty(store.getState().v2.NonConversational)
	) {
		let NonConversationalData = store.getState().v2.NonConversational[
			conversationId
		];

		if (NonConversationalData && !_.isEmpty(NonConversationalData)) {
			NonConversationalData.forEach((item, i) => {
				if (
					(item?.options?.tableId && message?.options?.tableId,
					item?.options?.tableId === message?.options?.tableId)
				) {
					NonConversationalData.splice(i, 1);
				}
			});
			NonConversationalData.push(message);
		} else {
			NonConversationalData = [message];
		}

		dispatch({
			type: RENDER_MESSAGE_TYPE_TABLE,
			data: {
				[conversationId]: NonConversationalData,
			},
		});
	} else {
		dispatch({
			type: RENDER_MESSAGE_TYPE_TABLE,
			data: {
				[conversationId]: [message],
			},
		});
	}
};

export const handleMessageTypeCalendar = (conversationId, message) => (
	dispatch
) => {
	if (
		store.getState().v2.NonConversational &&
		!_.isEmpty(store.getState().v2.NonConversational)
	) {
		let NonConversationalData = store.getState().v2.NonConversational[
			conversationId
		];

		if (NonConversationalData && !_.isEmpty(NonConversationalData)) {
			let view = "month";
			NonConversationalData.forEach((item, i) => {
				if (
					(item?.options?.controlId && message?.options?.controlId,
					item?.options?.controlId === message?.options?.controlId)
				) {
					view = NonConversationalData[i].options?.view;
					NonConversationalData.splice(i, 1);
				}
			});
			message.options.view = view;
			NonConversationalData.push(message);
		} else {
			NonConversationalData = [message];
		}

		dispatch({
			type: RENDER_MESSAGE_TYPE_CALENDAR,
			data: {
				[conversationId]: NonConversationalData,
			},
		});
	} else {
		dispatch({
			type: RENDER_MESSAGE_TYPE_CALENDAR,
			data: {
				[conversationId]: [message],
			},
		});
	}
};

export const handleCalendarChange = (conversationId, options, field, value) => (
	dispatch
) => {
	if (
		store.getState().v2.NonConversational &&
		!_.isEmpty(store.getState().v2.NonConversational)
	) {
		let NonConversationalData = _.cloneDeep(
			store.getState().v2.NonConversational[conversationId]
		);

		if (NonConversationalData && !_.isEmpty(NonConversationalData)) {
			NonConversationalData.forEach((item, i) => {
				if (
					item?.options?.controlId &&
					options?.controlId &&
					item?.options?.controlId === options?.controlId
				) {
					NonConversationalData[i].options[field] = value;
				}
			});
		}

		dispatch({
			type: CHANGE_CALENDAR,
			data: {
				[conversationId]: NonConversationalData,
			},
		});
	}
};

export const handleMessageTypeContainer = (conversationId, message) => (
	dispatch
) => {
	if (
		store.getState().v2.NonConversational &&
		!_.isEmpty(store.getState().v2.NonConversational)
	) {
		let NonConversationalData = store.getState().v2.NonConversational[
			conversationId
		];

		if (NonConversationalData && !_.isEmpty(NonConversationalData)) {
			let selectedTab = 0;
			NonConversationalData.forEach((item, i) => {
				if (
					item?.options?.controlId &&
					message?.options?.controlId &&
					item?.options?.controlId === message?.options?.controlId
				) {
					selectedTab = item.selectedTab;
					NonConversationalData.splice(i, 1);
				}
			});
			NonConversationalData.push({ ...message, selectedTab });
		} else {
			NonConversationalData = [{ ...message, selectedTab: 0 }];
		}

		dispatch({
			type: RENDER_MESSAGE_TYPE_CONTAINER,
			data: {
				[conversationId]: NonConversationalData,
			},
		});
	} else {
		dispatch({
			type: RENDER_MESSAGE_TYPE_CONTAINER,
			data: {
				[conversationId]: [message],
			},
		});
	}
};

export const handleContainerTabChange = (
	conversationId,
	message,
	selectedTab
) => (dispatch) => {
	if (
		store.getState().v2.NonConversational &&
		!_.isEmpty(store.getState().v2.NonConversational)
	) {
		let NonConversationalData = _.cloneDeep(
			store.getState().v2.NonConversational[conversationId]
		);

		if (NonConversationalData && !_.isEmpty(NonConversationalData)) {
			NonConversationalData.forEach((item, i) => {
				if (
					item?.options?.controlId &&
					message?.options?.controlId &&
					item?.options?.controlId === message?.options?.controlId
				) {
					NonConversationalData[i].selectedTab = selectedTab;
				}
			});
		}

		dispatch({
			type: CHANGE_CONTAINER_SELECTED_TAB,
			data: {
				[conversationId]: NonConversationalData,
			},
		});
	}
};

export const resetContainerFields = (conversationId, message) => (dispatch) => {
	if (
		store.getState().v2.NonConversational &&
		!_.isEmpty(store.getState().v2.NonConversational)
	) {
		let NonConversationalData = _.cloneDeep(
			store.getState().v2.NonConversational[conversationId]
		);

		// search for the message
		for (let i = 0; i < NonConversationalData.length; i++) {
			if (
				NonConversationalData[i] &&
				NonConversationalData[i].options &&
				message?.options &&
				NonConversationalData[i].options.controlId === message.options.controlId
			) {
				for (let j = 0; j < NonConversationalData[i].message.length; j++) {
					// check if it is form type
					if (
						NonConversationalData[i].message[j].type ===
						MessageTypeConstants.MESSAGE_TYPE_FORM2
					) {
						// reset for fields in forms
						for (
							let k = 0;
							k < NonConversationalData[i].message[j].message.fields.length;
							k++
						) {
							if (
								!NonConversationalData[i].message[j].message.fields[k].readOnly
							) {
								NonConversationalData[i].message[j].message.fields[k].value =
									"";
								if (
									NonConversationalData[i].message[j].message.fields[k]
										.results &&
									!_.isEmpty(
										NonConversationalData[i].message[j].message.fields[k]
											.results
									)
								) {
									NonConversationalData[i].message[j].message.fields[
										k
									].results = [];
								}
							}
						}
					}
				}
			}
		}

		dispatch({
			type: RESET_CONTAINER_FIELDS,
			data: {
				[conversationId]: NonConversationalData,
			},
		});
	}
};

export const handleMessageTypeForm2 = (conversationId, message) => (
	dispatch
) => {
	if (
		store.getState().v2.NonConversational &&
		!_.isEmpty(store.getState().v2.NonConversational)
	) {
		let NonConversationalData = _.cloneDeep(
			store.getState().v2.NonConversational[conversationId]
		);
		// Handle if any change in form
		if (message?.options?.action) {
			switch (message?.options?.action) {
				case "change":
					if (NonConversationalData && !_.isEmpty(NonConversationalData)) {
						// check which item in app it want to update
						NonConversationalData.forEach((item) => {
							// check if it's in a component like container
							if (message?.options?.parent) {
								if (
									item?.options?.controlId &&
									item?.options?.controlId === message?.options?.parent
								) {
									switch (item.messageType) {
										case MessageTypeConstants.MESSAGE_TYPE_CONTAINER:
											item.message.forEach((msg) => {
												if (
													message?.options &&
													msg?.message?.options?.formId ===
														message?.options?.formId
												) {
													if (message?.message?.remove) {
														msg.message.fields = msg.message.fields?.filter(
															(field) =>
																!message.message.remove.includes(field.id)
														);
													} else if (message?.message?.update) {
														msg.message.fields = msg.message.fields?.map(
															(field) =>
																message.message.update.id === field.id
																	? message.message.update
																	: field
														);
													} else if (message?.message?.fields) {
														for (
															let i = 0;
															i < message.message.fields.length;
															i++
														) {
															const newFiled = message.message.fields[i];
															let fieldsList = [];
															// Update the fileds
															msg.message.fields = msg.message.fields?.map(
																(field) => {
																	fieldsList.push(field.id);
																	if (field.id === newFiled.id) {
																		field = newFiled;
																	}
																	return field;
																}
															);

															// Incase new filed is added
															if (!fieldsList.includes(newFiled.id)) {
																msg.message.fields = [
																	...msg.message.fields,
																	newFiled,
																];
																// Sort if index is present
																if (newFiled.index) {
																	msg.message.fields = msg.message.fields.sort(
																		(a, b) =>
																			a.index > b.index
																				? 1
																				: b.index > a.index
																				? -1
																				: 0
																	);
																}
															}
														}
													}
												}
											});
											break;
									}
								}
							} else {
								// if it's independent form
								if (
									item?.options?.controlId &&
									message?.options?.controlId &&
									item?.options?.controlId === message?.options?.controlId
								) {
									switch (item.messageType) {
										case MessageTypeConstants.MESSAGE_TYPE_FORM2: {
											let newFields = message?.message?.fields;

											if (message?.message?.update) {
												newFields = message?.message?.update;
											} else if (message?.message?.fields) {
												newFields = message?.message?.fields;
											}

											for (let i = 0; i < newFields.length; i++) {
												let fieldsList = [];
												// Update the fileds
												item.message = item?.message?.map((field) => {
													fieldsList.push(field.id);
													if (field.id === newFields[i].id) {
														field = newFields[i];
													}
													return field;
												});

												// Incase new filed is added
												if (!fieldsList.includes(newFields[i].id)) {
													item.message = [...item.message, newFields[i]];
													// Sort if index is present
													if (newFields[i].index) {
														item.message = item?.message.sort((a, b) =>
															a.index > b.index ? 1 : b.index > a.index ? -1 : 0
														);
													}
												}
											}
											break;
										}
									}
								}
							}
						});
					}
					break;
				case "validation":
				case "results":
					if (NonConversationalData && !_.isEmpty(NonConversationalData)) {
						NonConversationalData.forEach((item) => {
							if (
								item?.options?.controlId &&
								message?.options?.controlId &&
								item?.options?.controlId === message?.options?.controlId
							) {
								switch (item.messageType) {
									case MessageTypeConstants.MESSAGE_TYPE_FORM2:
										if (message.message.field) {
											item.message.map((field) => {
												if (field.id === message.message.field) {
													field.results = message.message.results;
													field.validationResult =
														message.message.validationResult;
													field.validationMessage =
														message.message.validationMessage;
												}
												return field;
											});
										} else {
											item.genericError = message.message;
											if (!message.message.validationResult) {
												dispatch(
													showSnackbarV2(
														"error",
														message.message.validationMessage
													)
												);
											}
										}
										break;
								}
							} else if (
								item?.options?.controlId &&
								message?.options?.controlId &&
								item?.options?.controlId === message?.options?.parent
							) {
								// if the form is inside the conatiner
								item.message.map((msg) => {
									if (
										msg?.message?.options?.controlId ===
										message?.options?.controlId
									) {
										msg.message.fields.map((field) => {
											if (field.id === message.message.field) {
												field.results = message.message.results;
												field.validationResult =
													message.message.validationResult;
												field.validationMessage =
													message.message.validationMessage;
											}
											return field;
										});
									}
								});
							}
						});
					}
					break;
			}
		} else {
			if (NonConversationalData && !_.isEmpty(NonConversationalData)) {
				NonConversationalData.forEach((item, i) => {
					if (
						item?.options?.formId &&
						message?.options?.formId &&
						item?.options?.formId === message?.options?.formId
					) {
						NonConversationalData.splice(i, 1);
					}
				});
				NonConversationalData.push(message);
			} else {
				NonConversationalData = [message];
			}
		}

		dispatch({
			type: RENDER_MESSAGE_TYPE_FORM2,
			data: {
				[conversationId]: NonConversationalData,
			},
		});
	} else {
		dispatch({
			type: RENDER_MESSAGE_TYPE_FORM2,
			data: {
				[conversationId]: [message],
			},
		});
	}
};

export const resetForm2Fields = (conversationId, message) => (dispatch) => {
	if (
		store.getState().v2.NonConversational &&
		!_.isEmpty(store.getState().v2.NonConversational)
	) {
		let NonConversationalData = _.cloneDeep(
			store.getState().v2.NonConversational[conversationId]
		);

		// search for the message
		for (let i = 0; i < NonConversationalData.length; i++) {
			if (
				NonConversationalData[i] &&
				NonConversationalData[i].options &&
				message?.options &&
				NonConversationalData[i].options.controlId === message.options.controlId
			) {
				// search for the non readonly field and reset the value
				for (let j = 0; j < NonConversationalData[i].message.length; j++) {
					if (!NonConversationalData[i].message[j].readOnly) {
						NonConversationalData[i].message[j].value = "";
						if (
							NonConversationalData[i].message[j].results &&
							!_.isEmpty(NonConversationalData[i].message[j].results)
						) {
							NonConversationalData[i].message[j].results = [];
						}
					}
				}
			}
		}

		dispatch({
			type: RESET_FORM2_FIELDS,
			data: {
				[conversationId]: NonConversationalData,
			},
		});
	}
};

export const handleMessageTypeHtml = (conversationId, message) => (
	dispatch
) => {
	if (
		store.getState().v2.NonConversational &&
		!_.isEmpty(store.getState().v2.NonConversational)
	) {
		let NonConversationalData = store.getState().v2.NonConversational[
			conversationId
		];

		if (NonConversationalData && !_.isEmpty(NonConversationalData)) {
			NonConversationalData.forEach((item, i) => {
				if (
					(item?.options?.controlId && message?.options?.controlId,
					item?.options?.controlId === message?.options?.controlId)
				) {
					NonConversationalData.splice(i, 1);
				}
			});
			NonConversationalData.push(message);
		} else {
			NonConversationalData = [message];
		}

		dispatch({
			type: RENDER_MESSAGE_TYPE_HTML,
			data: {
				[conversationId]: NonConversationalData,
			},
		});
	} else {
		dispatch({
			type: RENDER_MESSAGE_TYPE_HTML,
			data: {
				[conversationId]: [message],
			},
		});
	}
};

export const flushNonConversationalMessages = (conversationId) => (
	dispatch
) => {
	// Do not flush menu message
	let NonConversationalData = store.getState().v2.NonConversational[
		conversationId
	];

	if (NonConversationalData && !_.isEmpty(NonConversationalData)) {
		NonConversationalData = [NonConversationalData[0]];
	} else {
		NonConversationalData = [];
	}

	dispatch({
		type: FLUSH_APPS,
		data: {
			[conversationId]: NonConversationalData,
		},
	});
};

export const removeNonConversationalMessages = (
	conversationId,
	message,
	history
) => (dispatch) => {
	let NonConversationalData = store.getState().v2.NonConversational[
		conversationId
	];

	if (!_.isEmpty(NonConversationalData)) {
		NonConversationalData.forEach((item, i) => {
			if (item.messageType !== MessageTypeConstants.MESSAGE_TYPE_MENU) {
				if (
					item?.options?.controlId &&
					message?.options?.controlId &&
					item?.options?.controlId === message?.options?.controlId
				) {
					NonConversationalData.splice(i, 1);
					if (
						NonConversationalData[i - 1] &&
						NonConversationalData[i - 1].messageType ===
							MessageTypeConstants.MESSAGE_TYPE_MENU
					) {
						let currentPath = history.location.pathname;
						let tempPath = currentPath.split("/");
						tempPath.splice(-2, 2);
						currentPath = tempPath.join("/");
						history.push(currentPath);
					}
				}
			}
		});
	}

	dispatch({
		type: REMOVE_APP,
		data: {
			[conversationId]: NonConversationalData,
		},
	});
};
