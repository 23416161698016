export const RENDER_MESSAGE_TYPE_TABLE = "RENDER_MESSAGE_TYPE_TABLE";
export const RENDER_MESSAGE_TYPE_CONTAINER = "RENDER_MESSAGE_TYPE_CONTAINER";
export const CHANGE_CONTAINER_SELECTED_TAB = "CHANGE_CONTAINER_SELECTED_TAB";
export const RENDER_MESSAGE_TYPE_FORM2 = "RENDER_MESSAGE_TYPE_FORM2";
export const RENDER_MESSAGE_TYPE_VIDEOCALL = "RENDER_MESSAGE_TYPE_VIDEOCALL";
export const RENDER_MESSAGE_TYPE_MENU = "RENDER_MESSAGE_TYPE_MENU";
export const RENDER_MESSAGE_TYPE_CALENDAR = "RENDER_MESSAGE_TYPE_CALENDAR";
export const RENDER_MESSAGE_TYPE_HTML = "RENDER_MESSAGE_TYPE_HTML";
export const CHANGE_CALENDAR = "CHANGE_CALENDAR";
export const RESET_FORM2_FIELDS = "RESET_FORM2_FIELDS";
export const RESET_CONTAINER_FIELDS = "RESET_CONTAINER_FIELDS";
export const FLUSH_APPS = "FLUSH_APPS";
export const REMOVE_APP = "REMOVE_APP";
